import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { siteMetadata } from '../../../gatsby-config';

const normalizeURL = url => /^http/.test(url) ? url : siteMetadata.url + url;

const generatePostJsonLd = node => ({
  '@context': 'https://schema.org',
  '@type': 'NewsArticle',
  headline: node.title,
  image: [
    normalizeURL(node.image),
  ],
  datePublished: new Date(node.date),
  dateModified: new Date(node.date),
  author: {
    '@type': 'Person',
    name: node.author,
  },
  publisher: {
    '@type': 'Organization',
    name: 'MLReef',
    logo: {
      '@type': 'ImageObject',
      url: siteMetadata.url + siteMetadata.logo,
      width: 60,
      height: 60,
    },
  },
});

const generateSiteJsonLd = () => ({
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  url: siteMetadata.url,
  name: siteMetadata.title,
  alternateName: siteMetadata.titleAlt ? siteMetadata.titleAlt : '',
});

const SEO = props => {
  const { node, isPost } = props;

  const { site: { siteMetadata: site } } = useStaticQuery(
    graphql`
      query FetchSiteMetadata {
        site {
          siteMetadata {
            title
            url
            description
            keywords
            logo
            userTwitter
          }
        }
      }
    `,
  );

  // this doesn't work in SSR
  // const { origin, href: url } = useLocation();
  const title = isPost ? `${node.title} | MLReef` : site.title;
  const description = isPost ? node.description : site.description;
  const image = normalizeURL(isPost ? node.image : site.logo);
  const path = site.url + (isPost ? node.path : '');
  const type = isPost ? 'article' : 'website';
  const { keywords } = site;

  const schemaOrgJSONLD = isPost
    ? generatePostJsonLd(node)
    : generateSiteJsonLd();

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta name="keywords" content={keywords} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={site.url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={path} />
      <meta property="og:image" content={image} />
      {site.fbAppId && <meta property="fb:app_id" content={site.fbAppId} />}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      {site.userTwitter && <meta name="twitter:creator" content={site.userTwitter} />}
      <meta property="twitter:url" content={path} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default SEO;
