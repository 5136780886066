import React from 'react';
import Layout from '../layout';
import SEO from '../components/SEO';
import './post.scss';

const SocialLinks = React.lazy(() => import('../components/SocialLinks'));

const PostTemplate = props => {
  const {
    pageContext: {
      html,
      frontmatter,
      excerpt
    },
    location,
  } = props;

  const node = {
    path: location.pathname,
    title: frontmatter.title,
    description: excerpt,
    author: frontmatter.author,
    date: frontmatter.date,
    image: frontmatter.image,
  };

  return (
    <Layout pathname={location.pathname} className="post-template">
      <SEO isPost node={node} />
      <div
        className="post-template-top-banner-container"
        style={{ backgroundImage: `url(${frontmatter.image})` }}
      >
        <div className="cover">
          <div className="m-auto">
            <h1 className="post-template-top-banner-title">
              {frontmatter.title}
            </h1>
            <div>
              {`by ${frontmatter.author} // ${frontmatter.date}`}
            </div>
          </div>
        </div>
      </div>
      <div className="post-template-content">
        { typeof window !== 'undefined' && (
          <React.Suspense fallback={<div>loading</div>}>
            <SocialLinks />
          </React.Suspense>
        )}
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  );
};

export default PostTemplate;
