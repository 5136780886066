/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.org/docs/gatsby-config/
 */

module.exports = {
  /* Your site config here */
  siteMetadata: {
    title: 'Collaborative Machine Learning development | MLReef',
    url: 'https://www.mlreef.com',
    siteUrl: 'https://www.mlreef.com',
    description: 'A centralized platform to host, run and track your machine learning work using built-in pipelines, containerized scripts and fully versioned experiment tracking.',
    keywords: 'machine learning, data operations, deep learning, MLOps, artificial intelligence, machine learning pipeline, git, git lfs, classification, clustering, neural networks, computer vision, supervised learning, applied AI',
    logo: '/images/MLReef_Logo_icon_01.png',
    email: 'hello@mlreef.com',
    mlreefUrl: 'https://www.mlreef.com',
    userTwitter: 'MLReef',
  },
  plugins: [
    {
      resolve: 'gatsby-plugin-polyfill-io',
      options: {
        features: [
          'Array.prototype.map',
          'Array.prototype.includes',
          'Array.prototype.find',
          'Object.entries',
          'URL',
          'URLSearchParams',
          'fetch',
        ],
      },
    },
    'gatsby-plugin-sass',
    'gatsby-transformer-remark',
    {
      resolve: 'gatsby-transformer-yaml',
      options: {
        // typeName: 'Yaml', // a fixed string
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'data',
        path: './data/',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'resources',
        path: './content/resources/',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'blog',
        path: './content/blog/',
      },
    },
    'gatsby-plugin-react-helmet',
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'MLReef',
        short_name: 'MLReef',
        start_url: '/',
        background_color: '#b2b2b2',
        theme_color: '#1D2B40',
        display: 'standalone',
        icon: 'static/images/MLReef_Logo_icon_01.png',
      },
    },
    {
      resolve: 'gatsby-plugin-sitemap',
      exclude: ['/wip/*'],
      options: {
        serialize: (args) => {
          const { path } = args;
          let priority = 0.1;
          if (path.match(/discover-features/)) {
            priority = 0.8;
          } else if (path.match(/book-a-demo/)) {
            priority = 0.8;
          }
          return {
            url: `https://www.mlreef.com${path}`,
            changefreq: 'monthly',
            priority,
          };
        },
      },
    },
    {
      resolve: 'gatsby-plugin-brotli',
      options: {
        extensions: ['css', 'html', 'js', 'svg', 'json'],
      },
    },
    {
      resolve: 'gatsby-plugin-alias-imports',
      options: {
        alias: {
          '@src': 'src',
          '@components': 'src/components',
          '@externals': 'externals',
        },
      },
    },
  ],
};
